import React from "react"
// import { Link } from "gatsby"
// import TypeMe, { Delay, Delete }  from 'react-typeme';

import Layout from "../components/layout"
// import Image from "../components/image"
import money from "../images/money.svg"
import thumbs from "../images/thumbs.svg"
import curve from "../images/curve.svg"
// import curverev from "../images/curverev.svg"
import cdsvideo from "../images/falling-cds.mp4"
import triangle from "../images/triangle.svg"
// import crmcurve from "../images/gradient-wave.svg"
import adoption from "../images/adoption.svg"
import attainment from "../images/attainment.svg"
import triangleup from "../images/triangle-up.svg"
import nocrmWhite from "../images/nocrm-white.svg"
import nocrmColor from "../images/nocrm-color.svg"
// import cdvideo from "../images/falling-cds.mov"
// import sassy from "../images/saasy-no-software.png"
import sassy from "../images/saasy-no-software.png"
// import logo from "../images/aviso-logo.svg"
import SEO from "../components/seo"
// import GaugeChart from 'react-gauge-chart'
import CountUp from 'react-countup';

import HubspotForm from 'react-hubspot-form';
// import '.'
// const VisibilitySensor = require('react-visibility-sensor');

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
	<section id="heroSection" className="uk-cover-container uk-height-viewport hero-section uk-light uk-overflow-hidden" data-uk-height-viewport>
		<img className="sassy" src={sassy} data-uk-parallax="rotate: 10,-10,10,-10; viewport: 0.4;" alt="" />
		<div className="uk-container uk-flex uk-flex-middle uk-flex-right uk-height-viewport">
			<h1 className="heading1 uk-width-2-3@m text-bold uk-margin-remove-bottom uk-animation-slide-left">
				This year <span> marks the 20th anniversary of Salesforce’s "No Software" campaign</span>
			</h1>
		</div>
	</section>
	<section id="cdSection" className="cd-section uk-light uk-height-large uk-position-relative uk-overflow-hidden">
		<div className="cd-container" data-uk-parallax="target: #remtext; opacity: 0,0,1; viewport: 0.5;">
			<video className="cds" autoPlay loop muted playsInline >
				<source src={cdsvideo} type="video/mp4" />
			</video>
		</div>
		<div className="uk-container uk-container-large">
			<div className="uk-margin-large-bottom" data-uk-parallax="opacity: 0,.7,1; y: 0,0,0; x: -200,-100,0; scale: 1,1,1; viewport: 0.2;">
				<h2 className="uk-width-3-5@m heading2 uk-margin-large-top" >
					"No Software" rallied against legacy packaged software...
				</h2>
			</div>
			<div className="uk-height-medium">
				&nbsp;
			</div>
			<div className="uk-position-bottom uk-margin-large-top uk-text-right" data-uk-parallax="opacity: 0,1,1; y: 0,0,0; x: -500,-200,0; scale: 1,1,1; viewport: 0.2;">
				<h2 className="remtext heading2 uk-margin-large-right">...remember CDs?</h2>
			</div>
		</div>
	</section>
	<section id="" className="uk-section-xlarge crm-section uk-light uk-padding-small-bottom uk-overflow-hidden">
		<div className="uk-container uk-margin-large-top">
			<div className="uk-flex uk-flex-center uk-margin-xlarge-top uk-margin-xlarge-bottom">
				<div className="uk-width-3-5 uk-width-3-5@m" data-uk-parallax="opacity: 0,1,1; y: -100,0,0; x: 0,0,0; scale: 1.4,1,1; viewport: 0.5;">
					<h2 className="heading2 uk-text-center uk-margin-remove-bottom uk-animation-slide-top-small" >
						We were told cloud CRM would be…
					</h2>
				</div>
			</div>
			<div className="uk-height-small">
				&nbsp;
			</div>
			<div className="" data-uk-parallax="opacity: 0,1,1; y: 0,0,0; x: -200,-100,0; scale: 1,1,1; viewport: 0.3;">
				<h2 className="uk-margin-large-top uk-margin-large-left heading2">Cost-effective <span className="color-accent uk-display-inline-block uk-margin-left"  data-uk-parallax="opacity: 0,0.5,1; scale:1,1,2; viewport:0.6">&times;</span></h2>
			</div>
			<div className="uk-height-small">
				&nbsp;
			</div>
			<div className="uk-flex uk-flex-right" data-uk-parallax="opacity: 0,1,1; y: 0,0,0; x: 200,100,0; scale: 1,1,1; viewport: 0.3;">
				<h2 className="uk-margin-large-bottom uk-margin-large-right heading2">Modern <span className="color-accent uk-display-inline-block uk-margin-left"  data-uk-parallax="opacity: 0,0.5,1; scale:1,1,2; viewport:0.6">&times;</span></h2>
			</div>
			<div className="uk-height-small">
				&nbsp;
			</div>
			<div className="" data-uk-parallax="opacity: 0,1,1; y: 0,0,0; x: -200,-100,0; scale: 1,1,1; viewport: 0.3;">
				<h2 className="uk-margin-large-bottom uk-margin-large-left heading2">User-Focused <span className="color-accent uk-display-inline-block uk-margin-left" data-uk-parallax="opacity: 0,0.5,1; scale:1,1,2.5; viewport:0.6">&times;</span></h2>
			</div>
		</div>
	</section>
	<section id="" className=" uk-height-viewport faces-section uk-light uk-background-fixed uk-position-relative" data-uk-height-viewport data-uk-parallax="bgy: -600,-300,0 ; opacity:1, 1, 1; viewport:0.6">
		<div className="triangle uk-height-medium" data-uk-parallax="y: -100,-50,-2; opacity:1, 1, 1; viewport:0.3">
			<img src={triangle} alt="trianglebg"/>
		</div>
		<div className="uk-container">
			<div className="uk-position-center">
				<div className="uk-flex uk-flex-center">
					<h2 className="text-bold uk-text-center heading2 uk-margin-remove-bottom uk-animation-slide-top-small">
						Cloud CRM today has become <br/><div className="color-bg" data-uk-parallax="bgx: -1000, -500, 0; viewport:0.6">the very things it said it would not be:</div>
					</h2>
				</div>
			</div>
		</div>
		<div className="triangle-up uk-position-bottom" data-uk-parallax="y:300,200,0; viewport:0.4">
			<img src={triangleup} alt=""/>
		</div>
	</section>
	<section id="" className="uk-light bg-grey uk-position-relative money uk-overflow-hidden" >
		<div className="uk-contianer uk-margin-xlarge-top uk-padding-large">
			<div className="uk-flex uk-flex-center uk-flex-middle uk-margin-xlarge-top">
				<img src={money} className="uk-position-absolute " alt="" data-uk-parallax="scale:.8,1,1; opacity: 0,.9,.6; viewport:0.4"/>
				<h2 className="uk-margin-large heading2 " data-uk-parallax="scale:.8,1,1; viewport:0.6">Expensive</h2>
			</div>
			<div className="uk-height-large">
				&nbsp;
			</div>
		</div>
		<div className="uk-height-medium uk-visible@m">
				&nbsp;
			</div>
	</section>
	
	<section id="" className="uk-light bg-grey uk-overflow-hidden">
		
		<div className="uk-flex uk-flex-center uk-flex-middle">
			<img src={thumbs} className="uk-position-absolute" alt="" data-uk-parallax="scale:.8,1,1; opacity: 0,.9,.6; viewport:0.6"/>
			<h2 className="heading2 " data-uk-parallax="scale:.8,1,1; viewport:0.6">and Unused</h2>
		</div>
		<div className="uk-height-medium">
			&nbsp;
		</div>
	</section>
	<section id="" className="uk-light " uk-parallax="background-color: #211e29,#c51c1c; viewport: 0.5">
		<div className="uk-height-small">
			&nbsp;
		</div>
		<div className="uk-flex uk-flex-center uk-flex-middle uk-height-1-1" data-uk-parallax="scale:.4,.8,1; viewport:0.5; opacity: 0, 0.4, 1">
			<h2 className="heading2 uk-margin-large-top uk-margin-large-bottom">
				It’s actually gotten worse.
			</h2>
		</div>
		<div className="uk-height-small">
			&nbsp;
		</div>
	</section>
	<section className="uk-section-xlarge bg-grey uk-light uk-position-relative">
		<div className="uk-container uk-container-small">
			<div className=" uk-flex uk-flex-center">
				<div className="uk-grid">
					<div className="uk-width-1-2@m">
						<div className="crmnumber uk-flex uk-flex-center uk-text-center uk-position-relative uk-height-1-1">
							<div className="dot"></div>
							<div className="dot2"></div>
							<div>
								<div className="num">
									<CountUp end={403} />
								</div>
								<div className="text-large ">CRMs on G2.com</div>
							</div>
						</div>
					</div>
					<div className="uk-width-1-2@m">
						&nbsp;
					</div>
					<div className="uk-width-1-3@m">
						&nbsp;
					</div>
					<div className="uk-width-2-3@m">
						<h3 className="heading3 bg-grey uk-padding-small uk-margin-small-left">
							Even as new CRMs keep piling up, the promise of unleashing revenue performance with CRM databases remains unfulfilled.
						</h3>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section id="" className="uk-section-large uk-light uk-position-relative bg-grey gauge-container">
		<div className="uk-container uk-flex-center uk-flex">
			<div className="uk-text-center uk-width-2-3@m">
				{/* <GaugeChart
					id="gauge-chart1"
					nrOfLevels={20}
					// arcsLength={[0.3, 0.5, 0.2]}
					colors={['#d10707', '#fff', '#fff']}
					percent={0.20}
					arcPadding={0.06}
				/> */}
				<img src={adoption} width="100%" alt="" className="image-hack" data-uk-parallax="scale:.5,.7,.8; opacity: 0,1,1; viewport:0.5" />
				<h2 className="heading2 uk-margin-remove-bottom" data-uk-parallax="scale:.7,.8,1; opacity: 0,1,1; y:-200,-100,0; viewport:0.5"><span className="color-accent">20%</span> <br/> ADOPTION</h2>
				<h3 className="uk-h3 uk-margin-small-top" data-uk-parallax="opacity: 0,1,1; y:-10,-5,0; viewport:0.5">Sales reps still hate using CRM.</h3>
			</div>
		</div>
	</section>
	<section id="" className="uk-section-xlarge uk-light uk-position-relative bg-grey gauge-container">
		<div className="uk-container uk-flex-center uk-flex">
			<div className="uk-text-center uk-width-2-3@m">
				{/* <GaugeChart
					id="gauge-chart3"
					nrOfLevels={20}
					// arcsLength={[0.3, 0.5, 0.2]}
					colors={['#d17607', '#fff', '#fff']}
					percent={0.43}
					arcPadding={0.06}
				/> */}
				<img src={attainment} width="100%" alt="" className="image-hack" data-uk-parallax="scale:.5,.7,.8; opacity: 0,1,1; viewport:0.5" />
				<h2 className="heading2 uk-margin-remove-bottom" data-uk-parallax="scale:.7,.8,1; opacity: 0,1,1; y:-200,-100,0; viewport:0.5"><span className="color-accent">43%</span> <br/> ATTAINMENT</h2>
				<h3 className="uk-h3 uk-margin-small-top " data-uk-parallax="opacity: 0,1,1; y:-10,-5,0; viewport:0.5">Quota attainment has plummeted.</h3>
			</div>
		</div>
	</section>
	<section id="" className="uk-height-viewport uk-light uk-position-relative make-good uk-overflow-hidden">
		<div className="uk-container uk-flex uk-flex-center uk-flex-middle uk-height-viewport" data-uk-parallax="scale:.7,.8,1; opacity: 0,1,1; y:-300,-100,0; viewport:0.5">
		<h2 className="uk-margin-large heading2 uk-margin-remove-bottom uk-text-center">
			Let's make good on what was promised 20 years ago.
			{/* <TypeMe strings={['Lets make good on what was promised 20 years ago.']} hideCursor /> */}
		</h2>
		</div>
	</section>
	<section className="hero uk-light uk-position-relative uk-overflow-hidden">
		<div className="uk-container ">
			<div className="uk-padding-small">
				<h1 className="uk-h1 text-bold uk-margin-remove-bottom uk-animation-slide-top-small uk-width-1-2">
					<img src={nocrmWhite} alt="#noCRM"/>
				</h1>
				<p className="uk-margin-right uk-width-3-5@m uk-margin-small-top uk-margin-remove-right@s uk-animation-fade">
					is a call to bring the focus back on sellers and performance, to a new horizon that focuses on deals, not data.
					{/* <TypeMe typingSpeed={800} hideCursor></TypeMe> */}
				</p>
			</div>
		</div>
		<div className="img-wrap">
			<img className="bg-curve" src={curve} alt="background" data-uk-img />
		</div>
	</section>
	<section className="uk-section-default nocrm-intro uk-overflow-hidden uk-position-relative" >
		<div className="uk-container uk-container-small">
			<div className="uk-padding-small">
				<h1 className="uk-h1 text-bold uk-margin-remove-bottom uk-animation-slide-top-small uk-width-1-2" >
					<img src={nocrmColor} alt="#noCRM" data-uk-parallax="opacity: 0, 0.4, 1; y:-2,-2,-2; viewport:0.4;"/>
				</h1>
				
				<h3 className="uk-margin-medium-top heading3l uk-flex uk-flex-right uk-margin-remove-right@s uk-animation-fade  uk-margin-large-bottom" data-uk-parallax="opacity: 0,1,1; y: 0,0,0; x: -200,-100,0; scale: 1,1,1; viewport: 0.3;">
					<div className="uk-width-2-3@m uk-text-right">is about deal guidance with AI</div>
				</h3>
			</div>
			<div className="uk-margin-large-top uk-text-center">
				<h2 className="heading2l">
					that is:
				</h2>
				<h3 className="uh3 uk-margin-medium-bottom" data-uk-parallax="opacity: 0,1,1; y: 0,0,0; x: -300,-150,0; scale: 1,1,1; viewport: 0.3;">
					actionable for reps 
					<div className="border-accent" ></div>
				</h3> <br/>
				<h3 className="uh3 uk-margin-medium-bottom" data-uk-parallax="opacity: 0,1,1; y: 0,0,0; x: -300,-150,0; scale: 1,1,1; viewport: 0.4;">
					predictable for sales leaders 
					<div className="border-accent"></div>
				</h3>
				<h2 className="heading2l uk-margin uk-margin-medium-bottom">
					and
				</h2>
				<h3 className="uh3" data-uk-parallax="opacity: 0,1,1; y: 0,0,0; x: -300,-150,0; scale: 1,1,1; viewport: 0.5;">
					saves money for CFOs and CROs 
					<div className="border-accent"></div>
				</h3>
				<div className="uk-height-small">
					&nbsp;
				</div>
				<h3 className="heading3l uk-text-bold uk-margin-large-top uk-margin-large-bottom uk-text-bottom">
					Find your Revenue True North with <br/> <img src={nocrmColor} width="200" alt="#noCRM"/> &nbsp;guidance
				</h3>
				<div className="uk-flex uk-flex-center">

					<div className="uk-width-4-5@m">
						<div className="uk-box-shadow-xlarge uk-background-secondary uk-border-rounded">
							{/* <iframe src="https://www.youtube-nocookie.com/embed/c2pz2mlSfXA?autoplay=0&amp;showinfo=0&amp;rel=0&amp;modestbranding=1&amp;playsinline=1" width="100%" height="400" frameBorder="0" allowFullScreen playsInline data-uk-responsive data-uk-video="automute: true"></iframe> */}

							<iframe title="noCRM" src="https://player.vimeo.com/video/373854074?color=ef0000&title=0&byline=0&portrait=0" frameborder="0" width="100%" height="400" allow="autoplay; fullscreen" allowFullScreen playsInline data-uk-responsive data-uk-video="automute: true; autoplay: inview"></iframe>
							{/* <script src="https://player.vimeo.com/api/player.js"></script> */}
						</div>
						
					</div>
				</div>
				<div className="uk-height-small">
					&nbsp;
				</div>
				{/* <video src=""></video> */}
			</div>
		</div>
	</section>
	<section className="uk-section-muted uk-section-large uk-position-relative uk-padding-small-bottom uk-overflow-hidden">
		<div className="uk-container uk-text-center">
			<h2 className="heading2l uk-margin-remove-bottom">
				Learn more about
			</h2>
			<h2 className="heading2l uk-text-bottom uk-margin-small-top uk-margin-bottom">
				<img src={nocrmColor} width="240" alt="#noCRM"/> AI Selling
			</h2>
			<div className="uk-margin-large-top">
				<div className="uk-child-width-1-3@m uk-grid" data-uk-grid>
					<div>
						<a href="https://blog.stormventures.com/aviso-a-nocrm-approach-to-sales-1710dbc224fb" target="_blank" rel="noreferrer noopener" className="uk-link-heading uk-card uk-card-default uk-card-hover uk-display-block">
							<div className="uk-card-media-top uk-position-relative uk-height-small uk-overflow-hidden">
								<img className="uk-width-1-1" src="https://miro.medium.com/max/2400/1*FTIABIjl9nyXy8OVgUDbjA.png" alt="" />
							</div>
							<div className="uk-card-body">
								<h3 className="uk-card-title">Why investors are embracing #noCRM</h3>
							</div>
						</a>
					</div>
					<div>
						<a href="http://info.aviso.com/sample-cost-takeout" target="_blank" rel="noreferrer noopener" className="uk-link-heading uk-card uk-card-default uk-card-hover uk-display-block">
							<div className="uk-card-media-top uk-position-relative uk-height-small uk-overflow-hidden">
								<img className="uk-width-1-1" src="https://info.aviso.com/hubfs/Screen%20Shot%202019-10-25%20at%2010.09.15%20AM.png" alt="" />
							</div>
							<div className="uk-card-body">
								<h3 className="uk-card-title">How to reduce non-core CRM cost by 38%</h3>
							</div>
						</a>
					</div>
					<div>
						<a href="https://www.aviso.com/blog/5-blind-spots-in-your-crm/" target="_blank" rel="noreferrer noopener" className="uk-link-heading  uk-card uk-card-default uk-card-hover uk-display-block">
							<div className="uk-card-media-top uk-position-relative uk-height-small uk-overflow-hidden">
								<img className="uk-width-1-1" src="https://www.aviso.com/wp-content/uploads/2019/02/Optimized-backlit-clouds-dawn-1118874-150x150.jpg" alt="" />
							</div>
							<div className="uk-card-body">
								<h3 className="uk-card-title">What are the blind spots in your CRM</h3>
							</div>
						</a>
					</div>
				</div>
				
			</div>
		</div>
		
	</section>
	{/* <div className="uk-position-relative">
		<div className="img-wrap-bottom">
			<img className="bg-curve-rev uk-width-1-1" src={curverev} alt="background" />
		</div>
	</div> */}
	
	
	<section className="uk-section-large uk-position-relative uk-light uk-overflow-hidden">
		{/* <div className="uk-height-medium">
			<img src={crmcurve} height="100%" alt=""/>
		</div> */}
		<div className="">
			<div className="uk-container uk-flex uk-flex-center uk-text-center">
				<div className="uk-width-2-3@m uk-text-center">
					<h2 className="heading2 uk-margin-remove-bottom">
						Experience Aviso's
					</h2>
					<h2 className="heading2 uk-text-middle uk-margin-small-top">
						<img src={nocrmColor} width="30%" alt="#noCRM"/> AI Compass
					</h2>
					<br/>
					<div className="form-block uk-box-shadow-large">
						<HubspotForm
							portalId='531684'
							formId='93d953a4-d645-449c-a60b-085e8e971074'
							cssClass="hubspotform"
							onSubmit={() => window.location.href='http://info.aviso.com/demo-thank-you'}
							onReady={(form) => console.log('Form ready!')}
							loading={<div>Loading...</div>}
						/>
					</div>
					
				</div>
			</div>
		</div>
	</section>

	<footer className="uk-section-default uk-text-center uk-section-small">
		© Copyright {new Date().getFullYear()} Aviso, Inc. All rights reserved.
	</footer>
  </Layout>
)

export default IndexPage
